import React, { FC, ReactNode, useEffect } from 'react'
import { initializeFirebaseMessaging } from './messaging'

type MessagingProviderProps = {
  children: ReactNode
}

export const MessagingProvider: FC<MessagingProviderProps> = ({ children }) => {
  useEffect(() => {
    initializeFirebaseMessaging().then(ok => {
      if (!ok) {
        return
      }
      // firebaseのSDKを使ってforegroundMessageを受け取る場合は以下に処理を書く
    })
  }, [])
  return <>{children}</>
}
