import React, { createContext, useContext, ReactNode, useState } from 'react'

type GlobalNavigationContextType = {
  shouldShowGlobalNavigation: boolean
  setShouldShowGlobalNavigation: (value: boolean) => void
}

const GlobalNavigationContext = createContext<GlobalNavigationContextType | undefined>(undefined)

export const GlobalNavigationProvider = ({ children }: { children: ReactNode }) => {
  const [shouldShowGlobalNavigation, setShouldShowGlobalNavigation] = useState(false)

  return (
    <GlobalNavigationContext.Provider
      value={{ shouldShowGlobalNavigation, setShouldShowGlobalNavigation }}
    >
      {children}
    </GlobalNavigationContext.Provider>
  )
}

export const useGlobalNavigation = () => {
  const context = useContext(GlobalNavigationContext)
  if (context === undefined) {
    throw new Error('useGlobalNavigation must be used within a GlobalNavigationProvider')
  }
  return context
}
