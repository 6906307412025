import React, { useEffect } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { NextUIProvider } from '@nextui-org/react'
import Head from 'next/head'
import { useRouter } from 'next/router'

import AppComponent from '@/components/molecules/AppComponent'
import { ToastProvider } from '@/contexts/ToastContext'
import ThemeProvider from '@/contexts/ThemeContext'
import { AuthProvider, CurrentUserProvider } from '@/contexts/AuthContext'
import TermsAgreementPopupProvider from '@/contexts/TermsAgreementPopupContext'
import { GoogleAnalytics, usePageView } from '@/pages/api/gtag'
import { MessagingProvider } from '@/firebase/Messaging'
import TermsAgreementPopup from '@/components/templates/Popup/TermsAgreementPopup'
import LPLayout from '@/components/lp/LPLayout'
import { GlobalNavigationProvider } from '@/contexts/GlobalNavigationContext'

import '@/styles/font.styles.scss'
import '@/styles/swiper/index.styles.scss'
import '@/styles/global.scss'
import '@/styles/home/pc.scss'
import '@/styles/home/sp.scss'
import { PwaProvider } from '@/contexts/PwaContext'
import type { AppProps } from 'next/app'

const queryClient = new QueryClient()

const FanmeApp = ({ children }: any) => {
  const { pathname } = useRouter()
  return (
    <TermsAgreementPopupProvider>
      {children}
      <TermsAgreementPopup ignore={/^\/(terms|_error)/.test(pathname)} />
    </TermsAgreementPopupProvider>
  )
}

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter()
  useEffect(() => {
    // スクロールスナップの影響でスクロールバーが2重になるため
    if (router.pathname === '/[creator_id]') {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }, [router])

  usePageView()

  const isLandingPage = ['/', '/about', '/contact'].includes(router.pathname)

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Head>
      <GoogleAnalytics />
      <AuthProvider>
        <PwaProvider>
          <CurrentUserProvider>
            <ThemeProvider>
              <QueryClientProvider client={queryClient}>
                <NextUIProvider>
                  <ToastProvider>
                    <MessagingProvider>
                      <FanmeApp>
                        {isLandingPage ? (
                          <LPLayout>
                            <AppComponent
                              isLimitWidth={false}
                              component={<Component {...pageProps} />}
                            />
                          </LPLayout>
                        ) : (
                          <GlobalNavigationProvider>
                            <AppComponent
                              isLimitWidth={router.pathname !== '/'}
                              component={<Component {...pageProps} />}
                            />
                          </GlobalNavigationProvider>
                        )}
                      </FanmeApp>
                    </MessagingProvider>
                  </ToastProvider>
                </NextUIProvider>
                {process.env.NODE_ENV === 'development' && (
                  <ReactQueryDevtools initialIsOpen={false} />
                )}
              </QueryClientProvider>
            </ThemeProvider>
          </CurrentUserProvider>
        </PwaProvider>
      </AuthProvider>
    </>
  )
}

export default App
