// context/PwaContext.tsx
import React, { createContext, useContext, useEffect, useState } from 'react'

const PwaContext = createContext<{ isPwa: boolean }>({ isPwa: false })

export const PwaProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isPwa, setIsPwa] = useState(false)

  useEffect(() => {
    const checkPwa = () => {
      // PWA のスタンドアロンモードを判別
      const isStandalone =
        window.matchMedia('(display-mode: standalone)').matches ||
        (window.navigator as any)?.standalone
      setIsPwa(isStandalone)
    }

    checkPwa()

    // iOS の visibilitychange イベントにも対応
    const handleVisibilityChange = () => checkPwa()
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  return <PwaContext.Provider value={{ isPwa }}>{children}</PwaContext.Provider>
}

export const usePwa = () => useContext(PwaContext)
