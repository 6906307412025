import { getApp, getApps, initializeApp } from 'firebase/app'
import localforage from 'localforage'
import { getMessaging, getToken, isSupported } from '@firebase/messaging'
import getConfig from 'next/config'
import { saveFcmToken, updateCreatorUid, updateFcmToken } from '@/pages/api/creator'

const fcmTokenKey = 'fanme_fcm_token'
const lastUpdatedKey = 'fcm_last_updated_at'
const currentCreatorUidKey = 'current_creator_uid'

const ONE_DAY = 24 * 60 * 60 * 1000

const { publicRuntimeConfig } = getConfig()

const app =
  getApps().length === 0 ? initializeApp(publicRuntimeConfig.FIREBASE_APP_OPTIONS) : getApp()

export const initializeFirebaseMessaging = async (): Promise<boolean> => {
  if (!(await isSupported())) {
    // firebase not supported
    return false
  }

  let serviceWorkerRegistration: ServiceWorkerRegistration | null = null

  if ('serviceWorker' in navigator) {
    try {
      serviceWorkerRegistration = await navigator.serviceWorker.register(
        '/firebase-messaging-sw.js',
      )
      console.log('[sw] messaging worker is installed')
    } catch (err) {
      console.error('[sw] messaging worker installation failed', err)
      return false
    }
  } else {
    console.error('Service Worker is not supported in this browser.')
    return false
  }

  try {
    const messaging = getMessaging(app)
    if (!messaging) {
      return false
    }
    const currentPermission = Notification.permission
    if (currentPermission !== 'granted') {
      return false
    }

    const newToken = await getToken(messaging, {
      vapidKey: publicRuntimeConfig.VAPID_KEY,
      serviceWorkerRegistration,
    })

    const [existingToken, lastUpdated, creatorUidInLocalforage] = await Promise.all([
      localforage.getItem<string | null>(fcmTokenKey),
      localforage.getItem<string | null>(lastUpdatedKey),
      localforage.getItem<string | null>(currentCreatorUidKey),
    ])

    const currentCreatorUid = getCurrentUserUid()

    if (
      currentCreatorUid &&
      existingToken === newToken &&
      creatorUidInLocalforage !== currentCreatorUid
    ) {
      const result = await updateFcmTokenCreatorUid(newToken)
      if (result) {
        await localforage.setItem<string>(currentCreatorUidKey, currentCreatorUid)
      }
    } else if (currentCreatorUid && existingToken !== newToken) {
      const result = await saveTokenInDatabase(newToken)
      if (result) {
        await Promise.all([
          localforage.setItem<string>(fcmTokenKey, newToken),
          localforage.setItem<string>(currentCreatorUidKey, currentCreatorUid),
        ])
      }
    } else if (currentCreatorUid && (!lastUpdated || Date.now() - Number(lastUpdated) > ONE_DAY)) {
      // トークンが同じで、かつ24時間以上経過している場合
      const result = await updateTokenTimestampInDatabase(newToken)
      if (result) {
        await localforage.setItem(lastUpdatedKey, Date.now().toString())
      }
    }
    return true
  } catch (err) {
    return false
  }
}

const saveTokenInDatabase = async (token: string) => {
  try {
    await saveFcmToken(token)
    return true
  } catch (error) {
    return false
  }
}

const updateTokenTimestampInDatabase = async (token: string) => {
  try {
    await updateFcmToken(token)
    return true
  } catch (error) {
    return false
  }
}

// 別のユーザーでログインした時に、fcm_tokensのcreator_uidを書き換える
const updateFcmTokenCreatorUid = async (token: string) => {
  try {
    await updateCreatorUid(token)
    return true
  } catch (error) {
    return false
  }
}

const getCurrentUserUid = (): string | null => {
  const fanmeToken = document.cookie
    .split('; ')
    .find(row => row.startsWith('fanme_token='))
    ?.split('=')[1]

  if (!fanmeToken) {
    return null
  }
  return decodeJWT(fanmeToken)
}

const decodeJWT = (token: string) => {
  try {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join(''),
    )
    // creator_uidを取得
    return JSON.parse(jsonPayload).sub
  } catch (e) {
    return null
  }
}
